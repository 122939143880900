import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { RichTextField } from "@prismicio/types";
import { CheckCircleFilled } from "components/Icons";
import { Image } from "lib/imgproxy";

/**
 * Props for `StreamlineSlice`.
 */
export type StreamlineSliceProps =
    SliceComponentProps<Content.StreamlineSliceSlice>;

/**
 * Component for "StreamlineSlice" Slices.
 */
const StreamlineSlice = ({ slice }: StreamlineSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex w-full flex-col items-center gap-[132px] lg:flex-row"
    >
        <div className="relative hidden aspect-[1.063] h-[490px] w-fit lg:block">
            <Image
                alt={slice.primary.image.alt ?? ""}
                src={slice.primary.image.url ?? ""}
                layout="fill"
                objectFit="contain"
                unoptimized
            />
        </div>
        <div className="flex flex-col gap-10 lg:gap-8">
            <div className="flex flex-col gap-5 px-4 text-center lg:px-0 lg:text-start">
                <div className="typography-h2 lg:typography-h1 font-semibold text-blue-grey-900">
                    {slice.primary.title}
                </div>
                <div className="typography-main text-blue-grey">
                    {slice.primary.description}
                </div>
            </div>
            <div className="flex justify-end overflow-hidden lg:hidden">
                <div className="relative mx-auto aspect-[1.063] h-fit w-[90%] lg:w-full">
                    <Image
                        alt={slice.primary.image.alt ?? ""}
                        src={slice.primary.image.url ?? ""}
                        layout="fill"
                        objectFit="contain"
                        unoptimized
                        sizes="(max-width: 400px) 300px, 500px"
                    />
                </div>
            </div>
            <div className="flex flex-col items-center gap-2 px-4 lg:items-start lg:px-0">
                <h4 className="typography-h4 font-bold text-blue-grey-900">
                    {slice.primary.example_title}
                </h4>
                <ExamplesRichText field={slice.primary.example_list} />
            </div>
        </div>
    </section>
);

const ExamplesRichText = ({
    field,
}: {
    field?: RichTextField | null;
}): JSX.Element => (
    <div className="flex flex-1 flex-col gap-3 [&_ul]:flex [&_ul]:flex-col [&_ul]:gap-3">
        <PrismicRichText
            field={field}
            components={{
                listItem: ({ children }) => (
                    <li className="typography-sub flex gap-3 text-blue-grey-900">
                        <CheckCircleFilled className="text-success" />
                        {children}
                    </li>
                ),
            }}
        />
    </div>
);

export default StreamlineSlice;
